<template>
  <div>
    <el-dialog title="二级学科素养"
               :visible.sync="dialogVisible"
               :close-on-click-modal="false"
               :append-to-body="true"
               width="1200rem"
               :before-close="handleClose">
      <el-table :data="tableData"
                height="450rem"
                style="width: 100%">
        <el-table-column prop="text"
                         align="left"
                         label="学科素养">
          <template slot-scope="{row}">
            <div class="checkpoint">
              {{row.capability_name}}
            </div>
          </template>
        </el-table-column>
        <el-table-column width="200"
                         label="操作">
          <template slot-scope="{row}">
            <div class="btn"
                 @click="doPaper(row)">
              开始训练
            </div>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>
<script>

import { getCapability } from '@/api/capability.js'
export default {
  data () {
    return {
      form: {},
      tableData: [],
      dialogVisible: false,
    }
  },
  methods: {
    handleClose () {
      this.dialogVisible = false
    },
    doPaper (row) {
      let params = {
        subject_id: row.subject_id,
        capability_id: row.capability_id,
        // is_weak: 0,
        type: 18,
      }
      this.handleClose()
      this.$router.push('/doPaper?type=18&form=' + JSON.stringify(params))
    },
    async initList (params) {
      const { data } = await getCapability(params)
      var copyData = data.list.filter(item => item.capability_id == params.capability_id );
      this.tableData = copyData[0].child
    }
  
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__header {
  font-size: 20rem;
  font-weight: bold;
  color: black;
  text-align: center;
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: white;
  background: #cccccc;
  border-radius: 50%;
  padding: 4rem;
}

::v-deep .el-dialog {
  border-radius: 20rem;
  height: 600rem;
  margin-top: 198rem !important;
  overflow: hidden;
}
::v-deep .el-dialog__body {
  padding: 30rem 20px;
}

::v-deep .el-table th,
.el-table tr {
  background: #f6f6f6;
}
::v-deep .cell {
  text-indent: 60rem;

  font-size: 20rem;
}

.checkpoint {
  font-weight: bold;
  color: #333333;
  font-size: 20rem;
}
.btn {
  width: 140rem;
  height: 44rem;
  background: #2196f3;
  border-radius: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20rem;
  font-weight: bold;
  color: #ffffff;
  text-indent: 0;
  cursor: pointer;
}
</style>